import React from "react"
import "./Post.styles.css"

export default function Post({ title, postDate, children }) {
  return (
    <div className="article">
      <h1>{title}</h1>
      <div className="post-date">written {postDate}</div>
      <div className="post-contents">{children}</div>
    </div>
  )
}
